import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Button,
  Divider,
  Typography,
  Result,
  Dropdown,
  Spin,
  Space,
  QRCode,
} from "antd";
import { Link, useParams } from 'react-router-dom'
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import './qr.css';

const { Title, Text } = Typography;
const Page = () => {
		const [ loading, setLoading ] = useState(false)
    const [ qrData, setQrData ] = useState(false)
    const [ active, setActive ] = useState(0)
    const [ step, setStep ] = useState(1)
    const [ type, setType ] = useState('checkin')
    const { authLiff, isLogin, sleep } = useContext(MainContext);

    useEffect(() => {  
      if(isLogin==true){
         onHistory()
      }
    }, [isLogin]);  
		const onHistory = async() => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/bitkub_summit/qr/step`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
              	setLoading(false)
              		setStep(1)
                  if(data.status==true){
                      setActive(data.step)

                  }
              }).catch((error) => {
              	setLoading(false)
              });
      } catch(error) {
      	setLoading(false)
      }
    }
    const onQr = async(data_type) => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/bitkub_summit/qr/gen/`+data_type, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  if(data.status==true){
                  		setStep(2)
                      setQrData(data)
                      setLoading(false)
                  }
              }).catch((error) => {
              	setLoading(false)
              });
      } catch(error) {
      	setLoading(false)
      }
    }

	const RenderQr = ({data}) => {
	  return (
	        <QRCode
	          errorLevel="H"
	          bgColor="#ffffff"
	          bordered={true}
	          size={200}
	          value={data?.data}
	        />
	  )
	}

	const renderViewQr = async(data) => {
		if((active==0 && data=='checkin') || (active==1 && data=='checkout')){
			setLoading(true)
			setType(data)
			await onQr(data)
		}
		
	}

	const backMenu = async() => {
		setLoading(true)
		setType(false)
		setQrData(false)
		await onHistory()
	}

	const RenderView = () => {
		return step==1? (
			<div className="bitkub-summit-frame">
					<a className={`bitkub-summit-btn ${active>=1? 'active' : ''}`} onClick={()=>renderViewQr('checkin')}>
						<img src={"/img/liff/event/bitkub_summit/check-in.svg?a=1"} className="checkin"/>
						<img src={"/img/liff/event/bitkub_summit/check-in-hover.svg?a=1"} className="checkin-hover"/>
					</a>
					<a className={`bitkub-summit-btn ${active==2? 'active' : ''}`} onClick={()=>renderViewQr('checkout')}>
						<img src={"/img/liff/event/bitkub_summit/check-out.svg?a=1"} className="checkout"/>
						<img src={"/img/liff/event/bitkub_summit/check-out-hover.svg?a=1"} className="checkout-hover"/>
					</a>
			</div>
		) : (
			<div className="bitkub-summit-frame" style={{textAlign:'center'}}>
			<h2>{type=='checkin'? 'Check In' : 'Check Out'}</h2>
      <div className="bitkub-summit-qr">
        {qrData && <RenderQr data={qrData}/>}
        
      </div>
      <Button onClick={()=>backMenu()}>Back</Button>

      </div>
		)
	}



  return (
  	<LinkAccount liffId={'1657440707-25OWvpWA'}>
  	<Spin spinning={loading}>
	  	<div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/bitkub_summit/bg.png?a=1)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
		  	<div className="mb-10">
	          <div style={{textAlign:'center',padding:'20px 50px 20px'}}>
	          	<img src={"/img/liff/event/bitkub_summit/header.svg?a=1"} style={{width:'100%'}}/>
	          </div>
	          <RenderView />
	          <div style={{textAlign:'center',padding:'20px 50px 0px', bottom:0}}>
	          	<img src={"/img/liff/event/bitkub_summit/footer.svg?a=1"} style={{width:'80%',margin:'0 auto'}}/>
	          </div>
	       </div>
	    </div>
	  </Spin>
	  </LinkAccount>
  );
};


export default Page;
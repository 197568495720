import React, { useEffect, useRef, useState, useContext } from "react"; 
import {
  Row,
  Col,
  Card,
  Button,
  Divider,
  Typography,
  Result,
  Dropdown,
  Segmented,
  Avatar,
  Image,
  Table,
  Space,
  message,
  Form,
  QRCode,
} from "antd";
import {
  ProForm,
  ProFormText,
  DrawerForm,
  ProFormSelect
} from '@ant-design/pro-components';
import { Link, useParams } from 'react-router-dom'
import Lottie from 'react-lottie';
import { MainContext } from "../../../../../contexts/main.jsx";
import LinkAccount from '../../../../../components/LinkAccount.jsx'
import * as animationData from '../../../../../assets/lottie/done.json'
import * as animationScand from '../../../../../assets/lottie/scand.json'

const { Title, Text, Paragraph } = Typography;
const numberFormatter = Intl.NumberFormat("en-US");

const Page = () => {
    const [form] = Form.useForm();
    const [ historyData, setHistoryData ] = useState(false)
    const [ historyIndex, setHistoryIndex] = useState({})
    const [ step, setStep ] = useState(0)
    const [ dataScan, setDataScan] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ menuTab, setMenuTab] = useState('Scan')
    const { authLiff, isLogin, sleep } = useContext(MainContext);

    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (_, record) => (
          <Space>
            <Avatar src={record.avatar} alt="" />
            <Text>{record.email?.split('@')[0] || '-'} ({record.type})</Text>
          </Space>
        ),
      },
      
      {
        title: 'Date Time',
        dataIndex: 'date_rec',
        key: 'date_rec',
      },
    ];


    useEffect(() => {  
      if(isLogin==true){
         onfetch()
      }
    }, [isLogin]);  

    const onfetch = async() => {
       await onHistory()
    }

    const onHistory = async() => {
      try {
          const accessToken = await authLiff.getAccessToken()
          fetch(`/api/liff/kuber/event/bitkub_summit/qr/history`, {
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                  }
              })
              .then((response) => response.json())
              .then(async(data) => {
                  setHistoryData(data.data)
              }).catch((error) => {
                setHistoryData([{error:0}])
              });
      } catch(error) {
        setHistoryData([{error:1}])
      }
    }


  const queryCode = async(value) => {
    const accessToken = await authLiff.getAccessToken()
    fetch('/api/liff/kuber/event/bitkub_summit/qr/scan', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
              code:value,
              sv:'none'
            }),
        })
        .then((response) => response.json())
        .then((data) => {
            if(data.status==true){
                setDataScan(data.info)
                setStep(1)
            }else{
              message.info(data.message);
            }
        })
        .catch((error) => {
            message.info('line not support');
        });
  }

  const onScanAction = async() => {
    try {
        setLoading(true)
        authLiff
          .scanCodeV2()
          .then(async(result) => {
                if(result && result.value){
                  await queryCode(result.value)
                  setLoading(false)
                }else{
                  setLoading(false)
                }
          }).catch((error) => {
              setLoading(false)
              message.info('line app not support camera');
          });
      
    } catch(error) {
      setLoading(false)
    }
  }

  const RenderScan = () =>{
    return (    
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
            Scan Qr
          </Title>
        </Col>
        <Col span={24}>
        {step==0? (
          <Card size={'small'}>
            <Result
              icon={<Lottie options={{
                  animationData: animationScand,
                  loop: false,
                  autoplay: true,
                }} height={200} width={200}/>}
              title={'Scan QR'}
              subTitle={"กรุณาทำการกดที่ปุ่ม Scan Qr"}
              style={{padding:0}}
              extra={[
                <Button type="primary" block size="large" onClick={onScanAction} loading={loading} disabled={loading}>
                  Scan Qr
                </Button>
              ]}
            />
          </Card>
        ) :  (
          <Card size={'small'}>
            {dataScan && (<Result
              title={dataScan?.title}
              subTitle={dataScan?.sub_title}
              icon={
                <div style={{position:'relative',width:100,margin:'30px auto 0'}}>
                  <Avatar size={100} src={dataScan?.avatar} />
                </div>
              }
              extra={[
                <Button type="primary" block size="large" onClick={()=>{
                    setStep(0);
                    setDataScan(false);
                }}>
                  Close
                </Button>
              ]}
              style={{padding:0}}
            >
              {dataScan && <div className="desc" style={{padding:0}}>
                <Paragraph>
                  <Text
                    strong
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Information:
                  </Text>
                </Paragraph>
                <Paragraph>
                  {dataScan?.name && (<><Text>Name : <Text copyable={{ text: dataScan?.name }}>{dataScan?.name}</Text></Text><br /></>)}
                  {dataScan?.employee_id && (<><Text>Employee ID : <Text copyable={{ text: dataScan?.employee_id }}>{dataScan?.employee_id}</Text></Text><br /></>)}
                </Paragraph>
              </div>}
            </Result>)}
          </Card>
        )}
        </Col>
      </Row>
    )
  }


  // /api/liff/:key/event/bitkub_summit/qr/history
  const RenderHistory = () =>{
    return (<div>
      <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={2} style={{ margin: 0,color:'#ffffff' }}>
                  History (Total {historyData.length})
                </Title>
              </Col>
              <Col span={24}>
                <Card size={'small'}>
                  {historyData && <Table dataSource={historyData} columns={columns} />}
                </Card>
              </Col>
      </Row>
    </div>)
  }

  // 
  return (
    <LinkAccount liffId={'1657440707-7bxzXmzA'}>
      <div style={{backgroundColor: '#000000',backgroundImage:'url(/img/liff/event/bitkub_summit/bg.png)',minHeight:'100vh',width:'100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',backgroundSize: 'cover',padding:20}}>
            <Row gutter={[0, 0]} className="mb-10">
              <Col span={24}>
              <Segmented
                defaultValue="center"
                style={{ marginBottom: 8 }}
                onChange={async(value) => {
                  onfetch()
                  setMenuTab(value)
                }}
                options={['Scan', 'History']}
              />
              </Col>
              <Col span={24}>
                  {menuTab=="Scan" && (<RenderScan  />)}
                  {menuTab=="History" && (<RenderHistory />)}
              </Col>
            </Row>
      </div>
    </LinkAccount>
  );
};


export default Page;